import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Grid,
  Button,
  extendTheme,
  Center,
  Text,
} from "@chakra-ui/react";
import Confetti from "react-confetti";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

const colors = ["gray.300", "yellow.300", "green.300", "blue.300"];
const colorNames = ["gray", "yellow", "green", "blue"];

const createInitialGrid = () => {
  const grid = [];
  for (let i = 0; i < 3; i++) {
    const row = [];
    for (let j = 0; j < 3; j++) {
      const randomColor = Math.floor(Math.random() * colors.length);
      row.push(randomColor); // Randomly assign a color to each cell
    }
    grid.push(row);
  }
  return grid;
};

const App = () => {
  const [grid, setGrid] = useState(createInitialGrid());
  const [gameWon, setGameWon] = useState(false);
  const [winningColor, setWinningColor] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const checkWinCondition = () => {
      const firstCellColor = grid[0][0];
      const allSameColor = grid.every((row) =>
        row.every((cell) => cell === firstCellColor)
      );
      if (allSameColor && firstCellColor !== 0) {
        setGameWon(true);
        setWinningColor(colorNames[firstCellColor]);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
      }
    };
    checkWinCondition();
  }, [grid]);

  const toggleCell = (x: number, y: number) => {
    const newGrid = grid.map((row, i) =>
      row.map((cell, j) => {
        if (i === x && j === y) return (cell + 1) % colors.length; // Cycle through colors
        if (i === x - 1 && j === y) return (cell + 1) % colors.length; // Toggle above
        if (i === x + 1 && j === y) return (cell + 1) % colors.length; // Toggle below
        if (i === x && j === y - 1) return (cell + 1) % colors.length; // Toggle left
        if (i === x && j === y + 1) return (cell + 1) % colors.length; // Toggle right
        return cell;
      })
    );
    setGrid(newGrid);
  };

  const resetGame = () => {
    setGrid(createInitialGrid());
    setGameWon(false);
    setWinningColor("");
    setShowConfetti(false);
  };

  return (
    <ChakraProvider theme={theme}>
      {showConfetti && <Confetti />}
      <Box
        textAlign="center"
        fontSize="xl"
        p={5}
        bg={gameWon ? "white" : "black"}
        minH="100vh"
      >
        <Button
          mb={5}
          onClick={resetGame}
          colorScheme={gameWon ? "blackAlpha" : "yellow"}
        >
          Reset Grid
        </Button>
        <Center>
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={1}
            w={["90vw", "90vw", "360px"]}
            h={["90vw", "90vw", "360px"]}
          >
            {grid.map((row, i) =>
              row.map((cell, j) => (
                <Box
                  key={`${i}-${j}`}
                  w="100%"
                  h="100%"
                  bg={colors[cell]}
                  onClick={() => toggleCell(i, j)}
                  border="1px"
                  borderColor="black"
                />
              ))
            )}
          </Grid>
        </Center>
        {gameWon && (
          <Text fontSize="2xl" color="black" mt={4}>
            The lights are on! Winning color: {winningColor}
          </Text>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default App;
